import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import queryString from 'query-string';
import NumberFormat from 'react-number-format';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest } from '../../../../../utils/request';
import { isBrowser, formatDay, formatDateTime } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';


function formatRow(item) {
  const {id, code, type, value, created_at: created, expires_after: expires, status } = item;
  return {
    code,
    type: type === 'percent' ? 'Percent' : 'Absolute Value',
    value: type === 'percent' ? <NumberFormat decimalScale={0} value={value} displayType="text" suffix="%" /> : <NumberFormat fixedDecimalScale decimalScale={2} value={value} displayType="text" prefix="$" />,
    created: formatDateTime(created),
    status: status ? 'Active' : 'Inactive',
    expires: expires ? formatDay(expires, 'M/D/Y') : 'Never',
    id,
  };
}

const AdminDiscountCodes = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/discount-codes',
        label: 'Discount Codes',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadDiscountCodes = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/discount-codes${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setDiscountCodes(result.data);
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadDiscountCodes();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'code',
      label: 'Code',
      isBold: true,
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'value',
      label: 'Value',
      isBold: true,
    },
    {
      key: 'expires',
      label: 'Expires After',
    },
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'created',
      label: 'Created',
    },
    {
      key: 'actions',
      label: '',
      isRight: true,
      render: (row) => (
        <Link
          to={`/app/admin/discount-code/edit?id=${row.id}`}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Edit
        </Link>
      ),
    },
  ];

  const actionLinks = [
    {
      label: 'Create Discount Code',
      href: '/app/admin/discount-code',
    },
  ];

  const baseUrl = '/app/admin/discount-codes';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/discount-codes',
    fields: [
      {
        name: 'code',
        label: 'Code',
        type: 'text',
      },
      {
        name: 'type',
        label: 'Type',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 'absolute',
            label: 'Absolute Value',
          },
          {
            value: 'percent',
            label: 'Percent',
          },
        ],
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        options: [
          {
            value: '',
            label: 'Select...',
          },
          {
            value: 1,
            label: 'Active',
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    ],
    submitLabel: 'Search Discount Codes',
  };

  return (
    <AdminContainer>
      <SEO title="Discount codes" />
      <Heading title="Discount Codes" links={actionLinks} />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Discount Codes" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminDiscountCodes;
